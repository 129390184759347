
import { translations, eventHandler } from "@/mixins";
import {
  Location,
  Base,
  SettingsFacilities,
  LocationFacilities,
} from "@/services/SOLO";
import { Facility as FacilityModel } from "@/models";
import Translations from "./components/Translations.vue";
import { FadeTransition, ScaleTransition } from "vue2-transitions";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import moment from "moment";
import { LoadingPanel } from "@/components";
import { mapGetters } from "vuex";
import {Concept} from "@/interfaces/SOLO";
import { Location as LocationModel } from "@/models";

interface ojbKey {
  [x: string]: string;
}

interface newLocation {
  id: string;
  name: ojbKey;
  status: string;
  telephone: string;
  pos: string;
  code: string;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": Boolean;
  "pickup-enabled": Boolean;
  "curbside-enabled": Boolean;
  "open-24-hours": Boolean;
  "is-inventory-sync-enabled": Boolean;
  "is-drive-thru-enabled": Boolean;
  "has-prompt-for-table-number": Boolean;
  "delivery-charge": number;
  logistics: any;
  "logistic-selection-type": any;
  line1: ojbKey;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
  "opening-hours-deliver": any;
  "opening-hours-pickup": any;
  "custom-fields": Array<any>;
  "image-uri": any;
  "schedule-disabled-location-hour": string;
  "delivery-charge-per-km": number;
  "delivery-maximum-distance": number;
}

@Component({
  components: {
    FadeTransition,
    ScaleTransition,
    Translations,
    LocationInfoForm: () => import("./components/LocationInfoForm.vue"),
    LocationFacilities: () => import("./components/LocationFacilities.vue"),
    LocationCustomFields: () => import("./components/LocationCustomFields.vue"),
    LocationDeliveryZone: () => import("./components/LocationDeliveryZone.vue"),
    ChangeLogs: () => import("./components/ChangeLogs.vue"),

  },
  mixins: [translations, eventHandler],
  computed: {
    ...mapGetters({
      conceptSettings: 'account/getConceptSettings',
    }),
  },
})
export default class LocationUpdate extends Vue {
  @Prop() locationId!: string;
  private locationLoaded: Boolean = false;
  private arrayLang: Array<string> = [];
  private langNum: number = 0;
  private gmapModal: Boolean = false;
  private loadingFacilities: Boolean = false;
  private locationFacilities: Array<FacilityModel> = [];
  private availableFacilities: Array<FacilityModel> = [];
  private toggle: Boolean = false
  private location: newLocation = {
    id: "",
    name: {},
    status: "",
    telephone: "",
    pos: "",
    code: "",
    email: "",
    country: "",
    lat: "",
    long: "",
    logistics: [],
    "logistic-selection-type": null,
    "delivery-enabled": true,
    "pickup-enabled": true,
    "curbside-enabled": true,
    "open-24-hours": false,
    "is-inventory-sync-enabled": true,
    "is-drive-thru-enabled": true,
    "has-prompt-for-table-number": false,
    "delivery-charge": 0,
    line1: {},
    "promised-time-delta": {
      delivery: "",
      pickup: "",
    },
    "opening-hours": [],
    "opening-hours-deliver": [],
    "opening-hours-pickup": [],
    "custom-fields": [],
    "image-uri": '',
    "schedule-disabled-location-hour": '',
    "delivery-charge-per-km": 0,
    "delivery-maximum-distance": 99
  };

  translate!: Function;
  getLanguages!: Function
  conceptSettings!: any;
  private openingHours: Array<any> = [
    { day: 0, open: "", closed: "" },
    { day: 1, open: "", closed: "" },
    { day: 2, open: "", closed: "" },
    { day: 3, open: "", closed: "" },
    { day: 4, open: "", closed: "" },
    { day: 5, open: "", closed: "" },
    { day: 6, open: "", closed: "" },
  ];

  private openingHoursDelivery: Array<any> = [
    { day: 0, open: "", closed: "" },
    { day: 1, open: "", closed: "" },
    { day: 2, open: "", closed: "" },
    { day: 3, open: "", closed: "" },
    { day: 4, open: "", closed: "" },
    { day: 5, open: "", closed: "" },
    { day: 6, open: "", closed: "" },
  ];

  private openingHoursPickup: Array<any> = [
    { day: 0, open: "", closed: "" },
    { day: 1, open: "", closed: "" },
    { day: 2, open: "", closed: "" },
    { day: 3, open: "", closed: "" },
    { day: 4, open: "", closed: "" },
    { day: 5, open: "", closed: "" },
    { day: 6, open: "", closed: "" },
  ];
  getConceptSettings!: any;

  constructor() {
    super();

  }

  created() {
    
    //moment().tz('Etc/GMT+1').format('YYYY-MM-DD HH:mm ZZ');
    console.log('date: ', moment().format('YYYY-MM-DD HH:mm ZZ'));
    console.log('LocationUpdate - created: ', this.location, this.conceptSettings.attributes['default-timezone'], moment().tz(`Etc/GMT+8`).format('YYYY-MM-DD HH:mm ZZ'));

    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
    for (let i = 0; i < this.arrayLang.length; i++) {
      this.location.name[this.arrayLang[i]] = "";
      this.location.line1[this.arrayLang[i]] = "";
    }

    this.location["opening-hours"] = this.openingHours;
    this.location["opening-hours-deliver"] = this.openingHoursDelivery;
    this.location["opening-hours-pickup"] = this.openingHoursPickup;

    const promiseArr: any = [];
    this.arrayLang.forEach((lang: string) => {
      promiseArr.push(Location.fetchLocation(this.locationId, lang));
      // this.fetchLocation(this.locationId, lang);
    });
    Promise.all(promiseArr)
      .then((response: any) => {
        const timezone = this.conceptSettings.attributes['default-timezone'] || 'GMT+3';
        const tz = `Etc/${timezone}`;

        let primary = response[0].data.data.attributes;
        let secondary = response[1] && response[1].data && response[1].data.data && response[1].data.data.attributes;
        console.log('RES: ', primary, secondary);
        this.location.id = response[0].data.data.id;
        this.location.status = primary.status;
        this.location.telephone = primary.telephone;
        this.location.pos = primary.pos;
        // console.log(primary.pos, secondary.pos)
        this.location.email = primary.email;
        this.location.country = primary.country;
        this.location.lat = primary.lat;
        this.location.code = primary.code;
        this.location.long = primary.long;
        this.location["delivery-enabled"] = !!primary["delivery-enabled"];
        this.location["pickup-enabled"] = !!primary["pickup-enabled"];
        this.location["curbside-enabled"] = !!primary["curbside-enabled"];
        this.location["open-24-hours"] = !!primary["open-24-hours"];
        this.location["is-inventory-sync-enabled"] = !!primary["is-inventory-sync-enabled"];
        this.location["is-drive-thru-enabled"] = !!primary["is-drive-thru-enabled"];
        this.location["has-prompt-for-table-number"] = !!primary["has-prompt-for-table-number"];
        this.location["delivery-charge"] = primary["delivery-charge"];
        this.location["logistics"] = primary["logistics"];
        this.location["image-uri"] = primary["image-uri"];
        this.location["logistic-selection-type"] = primary["logistic-selection-type"];
        this.location["custom-fields"] = primary["custom-fields"];
        this.location["promised-time-delta"].delivery = primary["promised-time-delta"].delivery;
        this.location["promised-time-delta"].pickup = primary["promised-time-delta"].pickup;
        this.location["schedule-disabled-location-hour"] = primary["schedule-disabled-location-hour"];
        this.location["delivery-maximum-distance"] = primary["delivery-maximum-distance"];
        this.location["delivery-charge-per-km"] = primary["delivery-charge-per-km"];

        if (primary['opening-hours']) {
          primary["opening-hours"].map((hours: { [x: string]: any }, index: any) => {
            this.location["opening-hours"][index].day = hours.day;
            this.location["opening-hours"][index].open = moment(`${hours.open}`,"hh:mm").format("hh:mm A");
            this.location["opening-hours"][index].closed = moment(`${hours.closed}`,"hh:mm").format("hh:mm A");
          });
        }

        if(primary['opening-hours-deliver'] && primary['opening-hours-pickup']) {
          this.toggle = true;
        }

        if (primary["opening-hours-deliver"]) {
          primary["opening-hours-deliver"].map((hours: { [x: string]: any }, index: any) => {
            this.location["opening-hours-deliver"][index].day = hours.day;
            this.location["opening-hours-deliver"][index].open = moment(`${hours.open}`,"hh:mm").tz(tz, true).utc().format("hh:mm A");
            this.location["opening-hours-deliver"][index].closed = moment(`${hours.closed}`,"hh:mm").tz(tz, true).utc().format("hh:mm A");
          });
        }

        if (primary["opening-hours-pickup"]) {
          primary["opening-hours-pickup"].map((hours: { [x: string]: any }, index: any) => {
            this.location["opening-hours-pickup"][index].day = hours.day;
            this.location["opening-hours-pickup"][index].open = moment(`${hours.open}`,"hh:mm").tz(tz, true).utc().format("hh:mm A");
            this.location["opening-hours-pickup"][index].closed = moment(`${hours.closed}`,"hh:mm").tz(tz, true).utc().format("hh:mm A");
          });
        }

        this.location.name[this.arrayLang[0]] = primary.name;
        this.location.line1[this.arrayLang[0]] = primary.line1;

        if (secondary) {
          this.location.name[this.arrayLang[1]] = secondary.name;
          this.location.line1[this.arrayLang[1]] = secondary.line1;
        }
        this.locationLoaded = true;
      })

    this.fetchAvailableFacilities();
    this.fetchLocationFacilities();
  }

  private onLocationUpdated(location: Location) {
    this.$emit("location:updated", location);
  }

  private onLocationDeleted(location: Location) {
    this.$emit("location:deleted", location);
  }

  private fetchLocation(locationId: string, lang: string) {
    Location.fetchLocation(locationId, lang).then((response: any) => {
      let res = response.data.data.attributes;
      if (!this.langNum) {
        this.location.id = response.data.data.id;
        this.location.status = res.status;
        this.location.telephone = res.telephone;
        this.location.pos = res.pos;
        this.location.code = res.code;
        this.location.email = res.email;
        this.location.country = res.country;
        this.location.lat = res.lat;
        this.location.long = res.long;
        this.location["delivery-enabled"] = res["delivery-enabled"] ? true : false;
        this.location["pickup-enabled"] = res["pickup-enabled"] ? true : false;
        this.location["curbside-enabled"] = res["curbside-enabled"] ? true : false;
        this.location["open-24-hours"] = res["open-24-hours"] ? true : false;
        this.location["is-inventory-sync-enabled"] = res["is-inventory-sync-enabled"] ? true : false;
        this.location["is-drive-thru-enabled"] = res["is-drive-thru-enabled"] ? true : false;
        this.location["has-prompt-for-table-number"] = res["has-prompt-for-table-number"] ? true : false;
        this.location["delivery-charge"] = res["delivery-charge"];
        this.location["logistics"] = res["logistics"];
        this.location["logistic-selection-type"] = res["logistic-selection-type"];
        this.location["custom-fields"] = res["custom-fields"];
        this.location["promised-time-delta"].delivery = res["promised-time-delta"].delivery;
        this.location["promised-time-delta"].pickup = res["promised-time-delta"].pickup;
        this.location["delivery-charge-per-km"] = res["delivery-charge-per-km"];
        this.location["delivery-maximum-distance"] = res["delivery-maximum-distance"];

        res["opening-hours"].map((hours: { [x: string]: any }, index: any) => {
          this.location["opening-hours"][index].day = hours.day;
          this.location["opening-hours"][index].open = moment(`${hours.open}`,"hh:mm").format("hh:mm A");
          this.location["opening-hours"][index].closed = moment(`${hours.closed}`,"hh:mm").format("hh:mm A");
        });
        res["opening-hours-deliver"].map((hours: { [x: string]: any }, index: any) => {
          this.location["opening-hours-deliver"][index].day = hours.day;
          this.location["opening-hours-deliver"][index].open = moment(`${hours.open}`,"hh:mm").format("hh:mm A");
          this.location["opening-hours-deliver"][index].closed = moment(`${hours.closed}`,"hh:mm").format("hh:mm A");
        });
        res["opening-hours-pickup"].map((hours: { [x: string]: any }, index: any) => {
          this.location["opening-hours-pickup"][index].day = hours.day;
          this.location["opening-hours-pickup"][index].open = moment(`${hours.open}`,"hh:mm").format("hh:mm A");
          this.location["opening-hours-pickup"][index].closed = moment(`${hours.closed}`,"hh:mm").format("hh:mm A");
        });
      }
      this.location.name[lang] = res.name;
      this.location.line1[lang] = res.line1;
      this.langNum++;
      this.locationLoaded = true;
    });
  }

  private async fetchLocationFacilities() {
    const response = await LocationFacilities.all(this.locationId);
    this.locationFacilities = response.data.data;
  }

  private async fetchAvailableFacilities() {
    this.loadingFacilities = true;
    let response = await SettingsFacilities.all();
    this.availableFacilities = response.data.data;
    this.loadingFacilities = false;
  }

  private updateLocationFacilities(data: Array<FacilityModel>) {
    this.locationFacilities = data;
  }
}
